<div class="login-container" *ngIf="whitelabelData && whitelabelData.pd">
  <div class="login-section">
    <div class="login-screen">
      <div class="login-form-container">
        <div class="image-container">
          <img alt="" [attr.src]="whitelabelData['pd']['logoDark']" />
        </div>
        <div class="login-body">
            <router-outlet></router-outlet>
        </div>
      </div>
      <div class="login-carousel">
        <div
          [ngStyle]="{ 'margin-left': '-' + this.currentImage * 100 + '%' }"
          class="carousel-container"
        >
          <div
            *ngFor="let item of carousel; trackBy: trackByFn"
            class="carousel padding-bottom-0"
          >
            <img [attr.src]="item['image']" alt="" />
          </div>
        </div>
        <div
          [ngStyle]="{ 'margin-left': '-' + this.currentImage * 100 + '%' }"
          class="carousel-container"
        >
          <div
            *ngFor="let item of carousel; trackBy: trackByFn"
            class="carousel padding-top-0 padding-bottom-0"
          >
            <div>
              <h4 class="title">{{ item["title"] }}</h4>
              <p class="description">{{ item["description"] }}</p>
            </div>
          </div>
        </div>
        <div class="flex-center m-2">
          <div
            (click)="setImage(i)"
            *ngFor="let _ of carousel; let i = index; trackBy: trackByFn"
            [ngClass]="{ 'active-dot': this.currentImage == i }"
            class="carousel-dot"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
