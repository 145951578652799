<div class="confirmContainer">
    <div class="confirmBox">

        <!-- <div class="head-hold" [ngClass]="{'head-or':status==='Disable','head-gr':status==='Enable'}">
            
            
            <i (click)="cancel()" class="fa-solid fa-xmark"></i>
        </div>
            <div class="info-hold">
                

                <span *ngIf="status==='Disable'" class="d-flex"> 
                    <i class="fa-solid fa-circle-info info-or"></i> <span>Please be informed that the current process requires approximately 2 to 3 minutes to complete. We appreciate your patience.</span>
                </span>

                <span *ngIf="status==='Enable'"> <i class="fa-solid fa-circle-info info-gr"></i> Service is currently active.</span>

                <button *ngIf="status==='Disable'" class="btn btn-outline-success okbtn" (click)="no()">Start</button>

                <button *ngIf="status==='Enable'" class="btn btn-outline-primary okbtn" (click)="cancel()">Ok</button>

               
            </div> -->

            <div class="info-hold">
                <!-- <i class="fa-solid fa-circle-info"></i> -->
                <div  *ngIf="servicStatus === 'Disable' && disableStatus===''"  class="d-flex justify-content-start mt-2 ww100">
                <span   class="d-flex KBHeader"> 
                    <!-- <i class="fa-solid fa-circle-info info-or"></i>  -->
                    <span> All Services needs to be enabled before you can use this platform.</span>
                </span>
            </div>
            <div *ngIf="servicStatus !== 'Disable' && servicStatus !== 'Enable' && servicStatus === 'Creating' && disableStatus===''" class="d-flex justify-content-start mt-2 ww100">
                <span   class="d-flex KBHeader"> 
                    <!-- <i class="fa-solid fa-circle-info info-or"></i> -->
                     <span>This may take up to 2 minutes.</span>
                </span>
            </div>

            <div *ngIf="servicStatus !== 'Disable' && servicStatus !== 'Creating' && disableStatus===''" class="d-flex justify-content-start mt-2 ww100">
            <span class="d-flex KBHeader"> 
                <!-- <i class="fa-solid fa-circle-info info-gr"></i>  -->
                
                Service is currently active.</span>
        </div>

        <div *ngIf="disableStatus!==''" class="d-flex justify-content-start mt-2 ww100">
            <span class="d-flex KBHeader"> 
                <!-- <i class="fa-solid fa-circle-info info-or"></i> -->
                 All services have been deactivated.</span>
        </div>
                <div class="d-flex justify-content-start mt-2 ww100">
                <span class="d-flex flex-column serhold">
                    <span class="d-flex gp"><i class="fa-solid" [ngClass]="{'fa-circle-check':isbedrock,'fa-circle-xmark':!isbedrock}"></i> Bedrock</span>
                    <span class="d-flex gp"><i class="fa-solid" [ngClass]="{'fa-circle-check':istrans,'fa-circle-xmark':!istrans}"></i> Transcribe</span>
                    <span class="d-flex gp"><i class="fa-solid" [ngClass]="{'fa-circle-check':iscomp,'fa-circle-xmark':!iscomp}"></i> Comprehend</span>
                    <span class="d-flex gp"><i class="fa-solid" [ngClass]="{'fa-circle-check':ispolly,'fa-circle-xmark':!ispolly}"></i> Polly</span>
                    <span class="d-flex gp"><i class="fa-solid" [ngClass]="{'fa-circle-check':issagemaker,'fa-circle-xmark':!issagemaker}"></i> SageMaker</span>
                    <span class="d-flex gp"><i class="fa-solid" [ngClass]="{'fa-circle-check':iskendra,'fa-circle-xmark':!iskendra}"></i> Kendra</span>
                </span>
            </div>
                

                
                <!-- <span *ngIf="servicStatus === 'Disable' && disableStatus===''" class="hd" ><span class="cl" (click)="enableService()">Click here</span> to enable</span> -->

                <div *ngIf="servicStatus === 'Disable' && disableStatus===''" class="d-flex justify-content-end ww100">
                    <button (click)="enableService()" class="btn btn-success">Click here to enable</button>
                </div>
                

               <div class="loder-hold hd" *ngIf="servicStatus !== 'Disable' && servicStatus !== 'Enable' && servicStatus !== 'Active' && disableStatus===''"> <span   class="loader" ></span><span class="fb">{{progressPercent}}%</span><span class="pk">Enabling Services...</span></div>

               <!-- <div class="progress" *ngIf="servicStatus !== 'Disable' && servicStatus !== 'Enable' && servicStatus !== 'Active'">
                <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'" [attr.aria-valuenow]="progressPercent" aria-valuemin="0" aria-valuemax="100">
                    {{ progressPercent}}%
                </div>
               </div> -->

               <!-- <span class="hd" *ngIf="servicStatus !== 'Disable' && servicStatus !== 'Creating' && disableStatus===''" >Services Enabled. <span class="cl" (click)="progressDone()">Click here</span> to proceed</span> -->

               <div *ngIf="servicStatus !== 'Disable' && servicStatus !== 'Creating' && disableStatus===''" class="d-flex justify-content-end ww100">
                <button (click)="progressDone()" class="btn btn-success">Click here to proceed</button>
            </div>

               <!-- <span class="hd" *ngIf=" disableStatus!==''" ><span class="cl" (click)="cancel()">Click here</span> to proceed</span> -->

               <div *ngIf=" disableStatus!==''" class="d-flex justify-content-end ww100">
                <button (click)="progressDone()" class="btn btn-success">Click here to proceed</button>
            </div>
               
            </div>
    </div>
</div>