<!-- toggler -->
<!--<a class="navbar-brand" >

</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>-->
<!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
<button class="btn btn-primary" type="submit">Search</button> *ngIf="searchOnList(i.groupName,accountGroupSearchTerm)" -->
<!--<button class="btn float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
  <i class="bi bi-arrow-left-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
</button>-->
<!--<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
<!--<div class="leftmenu offcanvas offcanvas-start  show" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
  <div class="offcanvas-header">
    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
      <img style="width:50px" src="../assets/img/logo.png" />
    </h6>
  </div>-->

<div class="leftmenu" *ngIf="started">
  <div class="offcanvas-body px-0 max-overflow">
    <button
      class="menuCloseButton ground-border"
      (click)="hideLeftMenu()"
      id="menuClosebtn"
    >
      <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
    </button>

    <button class="ground-border" (click)="showLeftMenu()" id="menuShowbtn">
      <i class="fa fa-arrow-circle-right"></i>
    </button>

    <ul
      class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
      id="menu"
    >
      <li class="nav-item">
        <a
          routerLink="/superadmin/clients"
          class="nav-link text-truncate"
          title="Feedback"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg"
            alt=""
          />
          Clients
        </a>
      </li>
      <li class="nav-item">
        <a
          routerLink="/superadmin/admins"
          class="nav-link text-truncate"
          title="Feedback"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg"
            alt=""
          />
          Admins
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="position-wid-99">
  <div class="row">
    <div class="col pad-right">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">
          <div class="nav-item wid-175">
            <span class="nav-link text-truncate padd-0">
              <img class="wid-115" src="../assets/img/swayam_logo_v3.0.png" />
            </span>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item dropdown searchbox min-width-auto">
                <a
                  class="d-block text-decoration-none dropdown-toggle color-top"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <!-- <span class="color-family">{{ username }}</span> -->
                </a>
                <ul
                  class="dropdown-menu text-small"
                  aria-labelledby="dropdownUser1"
                  data-popper-placement="bottom-start"
                >
                  <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                      <li><a class="dropdown-item" href="#">Profile</a></li>-->
                  <li>
                    <span class="color-family text-bold">{{ username }}</span>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button class="dropdown-item" (click)="logout()">
                      Sign out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="dropdown text-end"></div>

            <div class="wid-75"></div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<div
  class="content min-vh-100 rightcontent"
  [ngStyle]="{
    marginLeft: !started ? '26px' : undefined
  }"
>
  <ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</div>

<!-- <app-global-search
      *ngIf="showSearchResult"
      (hideModal)="showSearchResult = false"
    ></app-global-search> -->
<!--<cft-viewer></cft-viewer>-->
<!-- <dash-expired *ngIf="expiresIn && expiresIn == 0"></dash-expired> -->
<!-- <router-outlet></router-outlet> -->

<!-- Chatbot -->
