import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
//import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { Subscription } from 'rxjs';

declare var $: any;
declare var window: any;

export let internetActive: boolean;

export let regions: any = {
  enabled: [],
  disabled: [],
};

export let cftrequired: any = {
  'tell-me': {
    cft: moment('09-05-2023', 'DD-MM-YYYY').valueOf(),
    link: '/dash/tell-me',
  },
};

export let emsIntegration: any = [
  '/dash/reports/view?reportType=inventory',
  '/dash/site24x7/monitors',
];

export interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
}

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css'],
})
export class DashComponent implements OnInit {
  userId = localStorage.getItem('eId');
  username = localStorage.getItem('un');
  cbsAccess: boolean = false;
  started: boolean = false;
  showITAS: boolean = false;
  masterEmailId: string | null = localStorage.getItem('masterId');
  currentPageLink: string | null = null;
  hideAccountTab: boolean = true;
  subType: string = localStorage.getItem('subType') || 'freetrial';
  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';

  plans: any = {
    standard: 'Standard',
    advanced: 'Advanced',
    freetrial: 'Free Trial',
  };

  whatsNew: any = [
    {
      name: 'Tell Me',
      url: '/dash/tell-me',
      permission: () => {
        return (
          this.checkIfAvaForUser('Monitoring') &&
          moment().valueOf() <
            moment('22-06-2023', 'DD-MM-YYYY').endOf('day').valueOf()
        );
      },
      validTill: '22-06-2023',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Tell+Me+Feature%404x.png',
    },
    {
      name: 'EC2 Cost Controls',
      url: '/dash/costopt/ec2',
      permission: () => {
        return (
          (this.checkIfAvaForUser('Cost Controls') ||
            this.checkIfAvaForUser('CostOptimization')) &&
          moment().valueOf() <
            moment('22-06-2023', 'DD-MM-YYYY').endOf('day').valueOf()
        );
      },
      validTill: '22-06-2023',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Optimization%404x.png',
    },
  ];

  totalPeriod: number = localStorage.getItem('totalPeriod')
    ? Number(localStorage.getItem('totalPeriod'))
    : 0;

  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  agl: any;
  expanded: any = {
    site24x7: false,
    infra: false,
    security: false,
    maintenance: false,
    expenditure: false,
    optimization: false,
  };

  BOMAccess: boolean = this.urlPrefix === 'admin';
  betaAccess: boolean = false;
  accessType: string | null = localStorage.getItem('acT');
  access: string | null = localStorage.getItem('accessType');
  customerType: string | null = localStorage.getItem('customerType');

  accountGroups: any = {
    list: [],
    selected: '',
  };
  clientNames: any = {
    list: [],
    selected: '',
  };
  accountIds: any = {
    list: [],
    selected: '',
  };
  searchValue: string = '';
  recentSearchs: any = [];
  regions: any = {
    enabled: [],
    disabled: [],
  };

  expiresIn: any = localStorage.getItem('expiresIn');
  //regions = [
  //  { id: 'ap-south-1', name: 'Asia Pacific (Mumbai)ap-south-1' },
  //  { id: 'us-east-1', name: 'US East (N. Virginia)us-east-1' },
  //  { id: 'us-east-2', name: 'US East (Ohio)us-east-2' },
  //  { id: 'us-west-1', name: 'US West (N. California)us-west-1' },
  //  { id: 'us-west-2', name: 'US West (Oregon)us-west-2' },
  //  { id: 'ap-northeast-3', name: 'Asia Pacific (Osaka-Local)ap-northeast-3' },
  //  { id: 'ap-northeast-2', name: 'Asia Pacific (Seoul)ap-northeast-2' },
  //  { id: 'ap-southeast-1', name: 'Asia Pacific (Singapore)ap-southeast-1' },
  //  { id: 'ap-southeast-2', name: 'Asia Pacific (Sydney)ap-southeast-2' },
  //  { id: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)ap-northeast-1' },
  //  { id: 'ca-central-1', name: 'Canada (Central)ca-central-1' },
  //  { id: 'eu-central-1', name: 'Europe (Frankfurt)eu-central-1' },
  //  { id: 'eu-west-1', name: 'Europe (Ireland)eu-west-1' },
  //  { id: 'eu-west-2', name: 'Europe (London)eu-west-2' },
  //  { id: 'eu-west-3', name: 'Europe (Paris)eu-west-3' },
  //  { id: 'eu-north-1', name: 'Europe (Stockholm)eu-north-1' },
  //  { id: 'me-south-1', name: 'Middle East (Bahrain)me-south-1' },
  //  { id: 'sa-east-1', name: 'South America (São Paulo)sa-east-1' },
  //  { id: 'ap-east-1', name: 'Asia Pacific (Hong Kong)ap-east-1' },
  //  { id: 'af-south-1', name: 'Africa (Cape Town)af-south-1' },
  //  { id: 'ap-southeast-3', name: 'Asia Pacific (Jakarta)ap-southeast-3' },
  //  { id: 'eu-south-1', name: 'Europe (Milan)eu-south-1' },
  //];

  selectedRegion = 'Asia Pacific (Mumbai) ap-south-1';
  selectedRegionId = 'ap-south-1';

  accountIdSearchTerm: any;
  clientNameSearchTerm: any;
  accountGroupSearchTerm: any;
  accessList: any = localStorage.getItem('menuList');
  showSearchResult: boolean = false;
  searchResults: any;
  currentRoute: string | null = null;

  notification: any[] = [];
  noticount: any = 0;
  viewNotificationTrigger = false;

  whitelabelData: any = {
    pd: {
      appName: "",
      logoDark: "",
      logoLight: "",
    },
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {
    this.whitelabelData = this.route.snapshot.data['data'];
    if (this.whitelabelData["pd"]) {
      localStorage.setItem(
        "whitelabelData",
        JSON.stringify(this.whitelabelData)
      );
      this.whitelabelData = this.route.snapshot.data['data'];

      if (this.whitelabelData["pd"]["logoLight"]) {
        this.changeFavicon(this.whitelabelData["pd"]["logoLight"]);
      }
    }
    if (this.accessList) {
      let temp: any = [];
      try {
        let accessList: any = JSON.parse(this.accessList);
        temp = [...Object.keys(accessList)];
        for (const key in accessList) {
          if (accessList.hasOwnProperty(key)) {
            const values = accessList[key].map(
              (value: any) => `${key}:${value}`
            );
            temp.push(...values);
          }
        }
        this.accessList = temp;
      } catch (error) {
        this.accessList = [];
      }
    }

    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentRoute = e.url;
        if (localStorage.getItem('accountStatus') == 'Inactive') {
          if (e.url.indexOf('/dash/renewal') < 0) {
            this.showExpired = true;
            this.notifier.loading(false);
          } else {
            this.showExpired = false;
          }
        } else {
          this.showExpired = false;
        }
        this.currentPageLink = e.url;

        this.checkForMandatory();
      }
    });
  }

  changeFavicon(newFaviconURL: any) {
    const favicon: any = document.getElementById("favicon");
    if (favicon) {
      favicon.href = newFaviconURL;
    } else {
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.type = "image/png";
      newFavicon.href = newFaviconURL;
      newFavicon.id = "favicon";
      document.head.appendChild(newFavicon);
    }
  }
  showExpired: boolean = false;

  dashServiveMessage: any;

  async ngOnInit() {
    this.notifier.loading(true);
    setTimeout(() => {
      this.hideLeftMenu();
    }, 500);
   

    // window.addEventListener("online", () => {
    //   alert("Internet is active");
    //   internetActive = true;
    // });
    // window.addEventListener("offline", () => {
    //   alert("Internet is down");
    //   internetActive = false;
    // });
    this.regions = regions;

    if (localStorage.getItem('accountStatus') == 'Inactive') {
      return false;
    }
    // this.fetchRecentSearch();
    // if (this.ut == 'user') {
    //   this.urlPrefix = 'client';
    // }

    if (this.customerType != 'mp') {
      this.started = true;
    }

    // await this.loadHeader();
    this.notifier.loading(false);
    this.notifier.changeAccountIdNotification('1234567890');
    //localStorage.setItem('agl', JSON.stringify(this.agl));
    // if (!localStorage.getItem('selectedRegion')) {
    //   localStorage.setItem('selectedRegion', this.regions[0].name);
    //   localStorage.setItem('selectedRegionId', this.regions[0].id);
    //   localStorage.setItem('regionId', this.regions[0].id);
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // } else {
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // }
    return true;
  }

  totalWhatsNew() {
    return this.whatsNew.filter((new_: any) => {
      return new_['permission']();
    });
  }

  mandatory: boolean = false;

  checkForMandatory() {
    let check: any = Object.keys(cftrequired).find((feature: string) => {
      return (
        window.location.pathname.indexOf(cftrequired[feature]['link']) > -1
      );
    });
    this.mandatory = check ? true : false;
  }

  checkForEMS() {
    return (
      emsIntegration.includes(this.currentPageLink) &&
      !localStorage.getItem('emsConfig') &&
      this.getCurrentAccount() &&
      !this.getCurrentAccount()['site_247_project_id']
    );
  }

  createBUCheck(event: any) {
    if (event) {
      localStorage.removeItem('accountListNew');
      if (this.urlPrefix == 'admin') {
        this.loadAccountList(localStorage.getItem('selectedClientList'));
      } else {
        this.loadAccounts();
      }
    }

    this.enableBU = false;
  }

  link: string | null = null;

  async loadCFTLink() {
    let apiURL: string = `https://prod.api.swayam.cloud/v3/cft`;

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let result: any = await this.apiServer.postDataPromis(apiURL, {}, header);

    if (result.status == '1' || result.s == '1') {
      this.link = result['template_url'].replace(
        'RootAdminEmailIdValue',
        this.userId || localStorage.getItem('eId')
      );
    }
  }

  async checkForBetaAccess() {
    let data = {
      action: 'check_user',
      uid: localStorage.getItem('eId'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/checkdevuser`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      if (result.msg == 'Y') {
        this.betaAccess = true;
      } else {
        this.betaAccess = false;
      }
    }
    //if (this.CBSPortal && !this.cbsAccess) {
    //  this.logout();
    //}
  }

  async checkForCBS() {
    let data = {
      emailID: localStorage.getItem('eId'),
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchcbsuserstatus`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      if (result.enableCBS) {
        this.cbsAccess = true;
      }
    }
  }

  async checkForCFT(account_id: any, select: boolean = true) {
    return new Promise(async (resolve: any, reject: any) => {
      if (account_id['cft_status'] == undefined) {
        account_id['cft_status'] = 'loading';
      }
      account_id['cft_status'] = true;
      resolve(true);
      return true;
      let data = {
        action: 'validate_access',
        iam_role_arn: `arn:aws:iam::${account_id['accountId']}:role/msopsstsrole`,
        session_name: 'Swayam@AssumeRoleValidate',
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/accounts/validate`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        account_id['cft_status'] = true;
        account_id['cft_latest_version'] = moment(
          result['LatestStackVersion'],
          'DD-MM-YYYY'
        ).valueOf();
        account_id['cft_latest_version_raw'] = result['LatestStackVersion'];
        // result["StackVersion"] = "1.0.1";
        try {
          if (moment(result['StackVersion'], 'DD-MM-YYYY', true).isValid()) {
            account_id['cft_current_version'] = moment(
              result['StackVersion'],
              'DD-MM-YYYY'
            ).valueOf();
          } else {
            account_id['cft_current_version'] = moment(
              '01-01-2023',
              'DD-MM-YYYY'
            ).valueOf();
          }
        } catch {
          account_id['cft_current_version'] = moment(
            '01-01-2023',
            'DD-MM-YYYY'
          ).valueOf();
        }
        if (result['CloudShell']) {
          result['CloudShell']['Command'] = result['CloudShell'][
            'Command'
          ].replace('RootAdminEmailIdValue', this.userId);
        }
        account_id['cft_latest_url'] = result['CloudShell'];
        account_id['cft_deployed_region'] = result['StackRegion'];
        let currentAccount: any = this.getCurrentAccount();
        if (currentAccount) {
          if (currentAccount['accountId'] == account_id['accountId']) {
            this.started = true;
          }
        }
      } else {
        account_id['cft_status'] = false;
        account_id['cft_latest_url'] = this.link;
        if (
          this.customerType == 'mp' &&
          localStorage.getItem('accountId') == account_id['accountId'] &&
          this.selectedCloudPartner != 'azure'
        ) {
          this.started = false;
        }
      }

      if (
        localStorage.getItem('accountId') == account_id['accountId'] &&
        localStorage.getItem('role') == 'Client' &&
        select
      ) {
        this.setAccountList(account_id['accountId']);
      }
      resolve(result);
    });
  }

  async fetchAccountGroups() {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = { a: 'fetch' };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${environment.apiURL}/${this.urlPrefix}/Settings/accounts`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        result.agl = result.groups
          .map((group: string) => {
            return { groupName: group };
          })
          .sort(function (a: any, b: any) {
            if (a.groupName < b.groupName) {
              return -1;
            }
            if (a.groupName > b.groupName) {
              return 1;
            }
            return 0;
          });
        if (result.agl) {
          localStorage.setItem('accountGroups', JSON.stringify(result.agl));
          await this.loadAccountGroups();
          resolve({ s: '1', message: 'Account Groups Fetched' });
        } else {
          reject({ s: '0', message: 'Account Groups Not Found' });
        }
      }
      resolve(result);
    });
  }

  async loadAccountGroups() {
    return new Promise(async (resolve: any, reject: any) => {
      const accountGroups: any = localStorage.getItem('accountGroups');
      try {
        const data = JSON.parse(accountGroups);
        if (data.length > 0) {
          this.accountGroups.list = data.sort(function (a: any, b: any) {
            if (a.groupName < b.groupName) {
              return -1;
            }
            if (a.groupName > b.groupName) {
              return 1;
            }
            return 0;
          });
          const prevAccountGroup = localStorage.getItem('selectedAccountGroup');
          if (prevAccountGroup) {
            await this.setAccountGroup(prevAccountGroup);
          } else {
            await this.setAccountGroup(data[0].groupName);
          }
          resolve({ s: '1', message: 'ACCOUNT GROUPS LOADED' });
        } else {
          await this.fetchAccountGroups();
        }
      } catch (err) {
        await this.fetchAccountGroups();
      }
    });
  }

  async setAccountGroup(groupName: any) {
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    this.accountGroups.selected = groupName;
    const prevAccountGroup = localStorage.getItem('selectedAccountGroup');
    if (prevAccountGroup) {
      if (prevAccountGroup != groupName) {
        await this.fetchClientList(groupName);
      } else {
        this.loadClientList(groupName);
      }
    } else {
      await this.fetchClientList(groupName);
    }
    localStorage.setItem('selectedAccountGroup', groupName);
  }

  async fetchClientList(groupName: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = { a: 'fetchClients', groupName: groupName };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${environment.apiURL}/${this.urlPrefix}/Settings/accounts`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        if (result.data) {
          result.data = result.data
            .map((client: any) => {
              return { cmpName: client['name'], emailId: client['clientId'] };
            })
            .sort(function (a: any, b: any) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
          localStorage.setItem('clientList', JSON.stringify(result.data));
          await this.loadClientList(groupName);
          resolve({ s: '1', message: 'Client List Fetched' });
        } else {
          reject({ s: '0', message: 'Client List Not Found' });
        }
      }
      resolve(result);
    });
  }

  async loadClientList(groupName: any) {
    return new Promise(async (resolve, reject) => {
      const clientList: any = localStorage.getItem('clientList');
      try {
        const data: any = JSON.parse(clientList);
        if (data.length > 0) {
          this.clientNames.list = data.map((client: any) => {
            client['cmpName'] = client['cmpName'].trim();
            return client;
          });
          this.clientNames.list = data.sort(function (a: any, b: any) {
            if (a.cmpName < b.cmpName) {
              return -1;
            }
            if (a.cmpName > b.cmpName) {
              return 1;
            }
            return 0;
          });
          const prevClientList = localStorage.getItem('selectedClientList');
          if (
            prevClientList &&
            localStorage.getItem('selectedAccountGroup') == groupName
          ) {
            await this.setClientList(prevClientList);
          } else {
            await this.setClientList(data[0]['emailId']);
          }
          resolve({ s: '1', message: 'CLIENT LIST LOADED' });
        } else {
          await this.fetchClientList(groupName);
        }
      } catch (err) {
        await this.fetchClientList(groupName);
      }
    });
  }

  async setClientList(clientName: any) {
    $('#wait').show();
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    this.clientNames.selected = this.clientNames.list.find((client: any) => {
      return client.emailId == clientName;
    })['cmpName'];
    const prevClientList = localStorage.getItem('selectedClientList');
    if (prevClientList) {
      if (prevClientList != clientName) {
        await this.fetchAccountList(clientName);
      } else {
        this.loadAccountList(clientName);
      }
    } else {
      await this.fetchAccountList(clientName);
    }
    localStorage.setItem('selectedClientList', clientName);
    return true;
  }

  async fetchAzureSubscriptions(clientName: string | null) {
    let data: any = {
      action: 'list_subscriptions',
      user_id:
        this.urlPrefix == 'admin' &&
        (this.accountGroups['selected'] == 'DevelopmentTestGroup' ||
          this.accountGroups['selected'] == 'MinfyDevTeam')
          ? 'rakeshkumar.t@minfytech.com'
          : this.userId,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/central`;

    let result: any = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == '1' || result.s == '1') {
      result.subscriptions = result.subscriptions.map((subscription: any) => {
        return {
          accountId: subscription['subscription_id'],
          accountName: subscription['subscription_name'],
          cp: 'azure',
        };
      });
      this.accountIds.list = [...this.accountIds.list, ...result.subscriptions];
    } else {
      localStorage.removeItem('azureSubscriptions');
    }
  }

  async fetchAccountList(clientName: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      this.accountIds.list = [];
      let data = {
        a: 'fetchAccounts',
        clientId: clientName || this.userId,
        userType: this.ut,
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${environment.apiURL}/${this.urlPrefix}/Settings/accounts`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        if (result.config) {
          localStorage.setItem('emsConfig', JSON.stringify(result.config));
          this.loadMonitorGroups();
        } else {
          if (localStorage.getItem('emsConfig')) {
            localStorage.removeItem('emsConfig');
          }
        }
        if (result.data) {
          result.data = result.data
            .map((account: any) => {
              account['cp'] = 'aws';
              return account;
            })
            .sort(function (a: any, b: any) {
              if (a.accountName < b.accountName) {
                return -1;
              }
              if (a.accountName > b.accountName) {
                return 1;
              }
              return 0;
            });
          localStorage.setItem('accountId', result.data[0]['accountId']);
          // await this.fetchAzureSubscriptions(clientName);
          localStorage.setItem(
            'accountListNew',
            JSON.stringify([...this.accountIds.list, ...result.data])
          );
          await this.loadAccountList(clientName);
          resolve({ s: '1', message: 'Account Groups Fetched' });
        } else {
          // await this.fetchAzureSubscriptions(clientName);
          reject({ s: '0', message: 'Account Groups Not Found' });
        }
      }
      if (this.accountIds.list.length == 0) {
        this.loadRegions();
      }
      resolve(result);
    });
  }

  async loadAccountList(clientList: any) {
    return new Promise(async (resolve, reject) => {
      const accountList = localStorage.getItem('accountListNew');
      if (!accountList) {
        this.fetchAccountList(clientList);
        resolve({ s: '1', message: 'ACCOUNT LIST LOADED' });
        return;
      }
      try {
        const data = JSON.parse(accountList);
        if (data.length > 0) {
          this.accountIds.list = data.sort(function (a: any, b: any) {
            if (a.accountName < b.accountName) {
              return -1;
            }
            if (a.accountName > b.accountName) {
              return 1;
            }
            return 0;
          });
          const prevAccountList = localStorage.getItem('accountId');
          if (
            prevAccountList &&
            localStorage.getItem('selectedClientList') == clientList
          ) {
            localStorage.setItem('accountId', prevAccountList);
          } else {
            localStorage.setItem('accountId', data[0]['accountId']);
          }
          let promises: any = [];
          this.accountIds.list.forEach((account: any) => {
            if (account['cp'] != 'azure') {
              promises.push(this.checkForCFT(account));
            } else {
              account['cft_status'] = true;
            }
          });
          Promise.all(promises).then(async (values: any) => {
            localStorage.setItem(
              'accountIdData',
              JSON.stringify(this.accountIds)
            );
            if (
              prevAccountList &&
              localStorage.getItem('selectedClientList') == clientList
            ) {
              await this.setAccountList(prevAccountList);
            } else {
              await this.setAccountList(data[0]['accountId']);
            }
          });
          resolve({ s: '1', message: 'ACCOUNT LIST LOADED' });
        } else {
          await this.fetchClientList(this.accountGroups.selected);
        }
      } catch (err) {
        console.log('LOADING ACCOUNT LIST', err);
        await this.fetchClientList(this.accountGroups.selected);
      }
    });
  }

  async setAccountList(accountId: any) {
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    const accountList: any = localStorage.getItem('accountListNew');
    try {
      const data = JSON.parse(accountList);
      let account = data.find((account: any) => {
        return account.accountId == accountId;
      });

      if (!account) {
        account = this.accountIds.list.find((account_: any) => {
          return account_.accountId == accountId;
        });
      }

      this.accountIds.selected = account.accountName;
      if (
        !account['cft_status'] &&
        this.customerType == 'mp' &&
        this.selectedCloudPartner != 'azure'
      ) {
        this.started = false;
        this.router.navigate(['/dash/accounts/aws']);
      } else {
        this.started = true;
      }
      localStorage.setItem('accountId', account.accountId);
      if (account) {
        localStorage.setItem('swayam_jira_org_id', account.jira_org_id);
        localStorage.setItem('swayam_jira_org_name', account.jira_org_name);
        localStorage.setItem('swayam_jira_project_id', account.jira_project_id);
        localStorage.setItem(
          'swayam_jira_project_key',
          account.jira_project_key
        );
        localStorage.setItem(
          'swayam_jira_project_name',
          account.jira_project_name
        );
        localStorage.setItem(
          'swayam_site_247_project_id',
          account.site_247_project_id
        );
        localStorage.setItem(
          'site_247_project_name',
          account.site_247_project_name
        );
        localStorage.setItem('monitoringUrl', account.monitoringUrl);
      }
    } catch (err) {}

    let account: any = this.accountIds.list.find((account_: any) => {
      return account_.accountId == accountId;
    });

    this.selectedCloudPartner = account['cp'] || '';

    if (
      !account['cft_status'] &&
      this.customerType == 'mp' &&
      this.selectedCloudPartner != 'azure'
    ) {
      this.started = false;
      this.router.navigate(['/dash/accounts/aws']);
    } else {
      this.started = true;
    }

    this.selectAccountId(
      this.accountIds.list.find((account_: any) => {
        return account_.accountId == accountId;
      })
    );
    return true;
  }

  //TODO : save on Change

  checkIfAvaForUser(routeName: string): boolean {
    if (
      this.accessList.length === 0 ||
      this.accessList.indexOf(routeName) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  async navigateTo(param: any) {
    // await this.setRecentSearch(param);
    this.searchValue = '';
    this.router.navigateByUrl(param.url);
  }

  getPercentage(val?: number) {
    if (!val) {
      val = 0;
    }
    var r = 12;
    var c = Math.PI * (r * 2);

    if (val < 0) {
      val = 0;
    }
    if (val > 100) {
      val = 100;
    }

    let pct = ((100 - val) / 100) * c;

    return pct;
  }

  width: any;
  checkingCFT: boolean = false;

  async loadHeader() {
    //for notification
    // this.loadNotificationCount();
    // setInterval(() => {
    //   this.loadNotificationCount();
    // }, 3600000);

    this.loadCFTLink();
    this.notifier.loading(true);

    if (localStorage.getItem('role') == 'Client') {
      let data = localStorage.getItem('accountIdData');
      var max = -219.99078369140625;
      let percent = (this.expiresIn / 15) * 100;
      this.width = ((100 - percent) / 100) * max;
      this.accountIds.list = [];
      // if (data) {
      //   this.accountIds = {
      //     selected: JSON.parse(data).selected,
      //     list: JSON.parse(data).list,
      //   };
      //   if (
      //     this.accountIds &&
      //     this.accountIds.list &&
      //     this.accountIds.list.length == 0
      //   ) {
      //    true;true;
      //   } else {
      //     this.started = false;
      //   }
      //   localStorage.setItem("accountId", this.accountIds.list[0].accountId);
      //   localStorage.setItem("accountIdData", JSON.stringify(this.accountIds));
      //   this.selectAccountId(
      //     JSON.parse(data).list.find((account: any) => {
      //       //return account.accountName == this.accountIds.selected;
      //       return this.accountIds.selected.indexOf(account.accountId) > -1;
      //     })
      //   );
      //   this.accountIds.list.forEach((account: any) => {
      //     this.checkForCFT(account);
      //   });
      //   return true;
      // } else {
      //   let data = {
      //     a: "fetch",
      //     eid: localStorage.getItem("eId"),
      //   };
      //   let header = {
      //     Authorization: localStorage.getItem("t"),
      //   };
      //   let apiUrl = `${APIService.API_ENDPOINTV3}/market/user/accounts`;

      //   let result = await this.apiServer.postDataPromis(apiUrl, data, header);
      //   if (result.s == "1" || result.status == "1") {
      //     this.accountIds.list = result.accounts;
      //     if (
      //       this.accountIds &&
      //       this.accountIds.list &&
      //       this.accountIds.list.length == 0
      //     ) {
      //      true;true;
      //     } else {
      //       this.started = false;
      //     }
      //     this.accountIds.list.forEach((account: any) => {
      //       this.checkForCFT(account);
      //     });
      //     this.selectAccountId(this.accountIds.list[0]);
      //   } else {
      //    true;true;
      //   }
      //   // this.notifier.loading(false);
      //   return true;
      // }
      await this.loadAccounts();
      if (this.accountIds && this.accountIds.list.length > 0) {
        this.setAccountList(this.accountIds.list[0]['accountId']);
      }
      return false;
    }

    await this.loadAccountGroups();
    return true;
    let agl: any = localStorage.getItem('agl');
    if (agl) {
      console.log('agl', agl);
      this.agl = JSON.parse(agl);

      // this.loadAGL();
      return true;
    }

    this.notifier.loading(true);
    let data: any = {
      action: 'fetchAccountGroupsAndAccIDs',
      userId: this.userId,
      ut: this.ut,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;
    console.log('FETCHING HEADER DATA', this.urlPrefix);
    if (this.urlPrefix == 'admin') {
      // console.log('URL ADMIN:', this.urlPrefix);
      // apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;
      console.log('I AM ADMIN');
      await this.loadAccountGroups();
      return true;
    } else {
      console.log('URL ADMIN:', this.urlPrefix);
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccounts`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.s == '1' || result.status == '1') {
      this.notifier.loading(false);
      if (this.urlPrefix == 'client') {
        this.accountIds = {
          selected: result.list[0]['otherdetail'][0].accountName,
          list: result.list[0]['otherdetail'],
        };
        localStorage.setItem('accountId', this.accountIds.list[0].accountId);
        localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));
        this.selectAccountId(this.accountIds.list[0]);
        return true;
      }

      this.agl = result.agl;

      localStorage.setItem('agl', JSON.stringify(result.agl));

      //
      this.accountGroups = {
        selected: this.agl[0].groupName,
        list: [],
      };

      for (let i = 0; i < this.agl.length; i++) {
        this.accountGroups.list.push(this.agl[i]);
      }

      localStorage.setItem('selectedAccountGroup', this.accountGroups.selected);
      localStorage.setItem(
        'selectedAccountGroupData',
        JSON.stringify(this.accountGroups)
      );

      console.log(
        'this.accountGroups',
        JSON.stringify(
          this.accountGroups.list[0].accountGroup.accountGroupList
            .accountGroupDetails,
          null,
          2
        )
      );

      let value =
        this.accountGroups.list[0].accountGroup.accountGroupList
          .accountGroupDetails;
      this.clientNames = {
        selected: value[0].cmpName,
        list: value,
      };

      let clientName = this.clientNames.list[0];

      this.clientNames.selected = clientName.cmpName;

      localStorage.setItem('selectedClientName', this.clientNames.selected);
      localStorage.setItem(
        'selectedClientNameData',
        JSON.stringify(this.clientNames)
      );

      this.accountIds = {
        selected: clientName.accList[0].accountName,
        list: clientName.accList,
      };

      localStorage.setItem('selectedaccountIds', this.accountIds.selected);
      localStorage.setItem('accountId', clientName.accList[0].accountId);
      localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));

      //localStorage.removeItem('selectedAccountGroup');
      //localStorage.removeItem('selectedClientName');
      //localStorage.removeItem('selectedaccountIds');

      //localStorage.removeItem("selectedRegion");

      this.notifier.loading(false);

      //this.accountIds = {
      //  selected: "",
      //  list: []
      //};

      //this.regions = {
      //  selected: "",
      //  list: []
      //};
    } else {
      this.notifier.alert('Error', 'Error', 'Error Loading Account Groups');
      this.notifier.loading(false);
    }

    return true;
  }

  checkForSwayamEMS() {
    return localStorage.getItem('emsConfig');
  }

  enableBU: boolean = false;
  async loadMonitorGroups() {
    let account: any = localStorage.getItem('emsConfig');
    if (!account) {
      return;
    }
    let emsData: any = JSON.parse(account);
    let data: any = {
      action: 'list_monitor_groups',
      id_token: localStorage.getItem('idT'),
      zaaid: emsData['zaid'],
      login_user_name: this.username,
      login_user_email: this.userId,
      origin: 'swayam',
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      localStorage.setItem(
        'site24x7MonitorGroups',
        JSON.stringify(result['monitor_groups'])
      );
    }

    return;
  }

  continents: any = [];

  getRegions(continent: string, enabled: boolean = true) {
    return this.regions['enabled'].filter((region: any) => {
      return (
        this.getCurrentAccount() &&
        region.id.startsWith(continent) &&
        (!this.getCurrentAccount()['customeRegions'] ||
          (enabled
            ? this.checkRegion(this.getCurrentAccount(), region.id)
            : true))
      );
    });
  }

  getDisabledRegions(enabled: boolean = true) {
    return this.regions['disabled'].filter((region: any) => {
      return (
        this.getCurrentAccount() &&
        (!this.getCurrentAccount()['customeRegions'] ||
          (enabled
            ? this.checkRegion(this.getCurrentAccount(), region.id)
            : true))
      );
    });
  }

  async loadRegions() {
    this.continents = [];
    let data: any = {
      a: 'getregions',
      accountId: localStorage.getItem('accountId') || null,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/Settings/accounts`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.regions['enabled'] = result.regions.filter((region: any) => {
        if (this.continents.indexOf(region['id'].split('-')[0]) < 0) {
          this.continents.push(region['id'].split('-')[0]);
        }
        return region['status'] == 'enabled';
      });
      this.regions['disabled'] = result.regions.filter((region: any) => {
        return region['status'] != 'enabled';
      });
      let data = this.accountIds['list'].find((account: any) => {
        return account['accountId'] == localStorage.getItem('accountId');
      });

      if (data && data.regions && data.regions.length > 0) {
        let region: any = this.regions['enabled'].find((region: any) => {
          return region.id == data.regions[0];
        });

        if (region) {
          await this.setRegion(region, false);
        } else {
          await this.setRegion(this.regions['enabled'][0], false);
        }
      } else {
        if (
          !localStorage.getItem('regionId') &&
          this.regions['enabled'].length > 0
        ) {
          await this.setRegion(this.regions['enabled'][0], false);
          return true;
        } else {
          let check = this.regions['enabled'].find((region: any) => {
            return region.id == localStorage.getItem('regionId');
          });
          if (check) {
            await this.setRegion(check, false);
            return true;
          } else if (this.regions['enabled'].length > 0) {
            await this.setRegion(this.regions['enabled'][0], false);
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.message || result.error || result.error_message,
        'info',
        5000
      );
    }
    if (this.accountIds.list.length == 0) {
      this.notifier.loading(false);
    }

    return false;
  }

  getSelectedAccountId() {
    if (this.checkForDemoAccount()) {
      return '1234567890';
    } else {
      let account: any = localStorage.getItem('accountId');
      if (this.selectedCloudPartner == 'azure') {
        account = 'xxxx-xxxx-xxxx-xx' + account.slice(-5);
      }
      return account;
    }
    // return this.checkForDemoAccount()
    //   ? "1234567890"
    //   : localStorage.getItem("accountId");
  }

  getMiddleString(text: string) {
    return text.substring(text.indexOf('(') + 1, text.lastIndexOf(')'));
  }

  filterText: string = '';
  showMore: boolean = false;

  filteredResourceGroups() {
    return this.resourceGroups.filter((rg: any) => {
      return rg.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1;
    });
  }

  selectedResourceGroup: string | null = null;
  toggleResourceGroup(rg: any) {
    rg.selected = !rg.selected;
    let rg_ = this.resourceGroups.findIndex((rg_dict: any) => {
      return rg_dict == rg;
    });
    this.resourceGroups[rg_].selected = true;
    let selectedResourceGroups = this.resourceGroups.filter((rg_: any) => {
      return rg_.selected;
    });
    if (selectedResourceGroups.length > 5) {
      if (
        rg_ == selectedResourceGroups.length - 1 ||
        rg_ == this.resourceGroups.length - 1
      ) {
        if (
          localStorage.getItem('selectedResourceGroup') ==
          selectedResourceGroups[0].name
        ) {
          selectedResourceGroups[1].selected = false;
        } else {
          selectedResourceGroups[0].selected = false;
        }
      } else {
        if (
          localStorage.getItem('selectedResourceGroup') ==
          selectedResourceGroups[selectedResourceGroups.length - 1].name
        ) {
          selectedResourceGroups[selectedResourceGroups.length - 2].selected =
            false;
        } else {
          selectedResourceGroups[selectedResourceGroups.length - 1].selected =
            false;
        }
      }
    }
  }

  async loadAccounts() {
    this.notifier.loading(true);
    this.accountIds.list = [];

    let data: any = {
      a: 'fetch',
      clientId: localStorage.getItem('eId'),
      userType: this.ut,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/Settings/accounts`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' && result.accounts) {
      if (result.config) {
        this.loadMonitorGroups();
        localStorage.setItem('emsConfig', JSON.stringify(result.config));
      } else {
        if (localStorage.getItem('emsConfig')) {
          localStorage.removeItem('emsConfig');
        }
      }
      result.accounts = result.accounts.map((account: any) => {
        account['cp'] = 'aws';
        if (account['regions'] == undefined) {
          account['regions'] = [];
        }
        if (account['customeRegions'] != undefined) {
          account['customRegions'] = account['customeRegions'];
        }
        if (account['customRegions'] == undefined) {
          account['customRegions'] = true;
        }
        if (account['importType'] == undefined) {
          account['importType'] = 'automatic';
        }
        if (result.config) {
          account['site_247_project_id'] = result['config']['emsUid'];
          account['site_247_project_name'] = result['config']['emsName'];
        }
        return account;
      });
      this.accountIds.list = [...this.accountIds.list, ...result.accounts];

      let selectedAccount = localStorage.getItem('accountId');

      if (!selectedAccount) {
        localStorage.setItem('accountId', this.accountIds.list[0].accountId);
      }
      let account = this.accountIds.list.find((account: any) => {
        return account['accountId'] == selectedAccount;
      });
      if (!account) {
        localStorage.setItem('accountId', this.accountIds.list[0]['accountId']);
      }
      localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));
      let promises: any = [];

      this.accountIds.list.forEach((account: any) => {
        if (account['cp'] != 'azure') {
          promises.push(this.checkForCFT(account));
        } else {
          account['cft_status'] = true;
        }
      });
      Promise.all(promises).then((values: any) => {
        localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));
      });
    } else {
      this.accountIds.list = [];
      if (
        this.customerType == 'mp' &&
        this.ut == 'admin' &&
        !this.isUserAdmin
      ) {
        this.router.navigate(['/dash/accounts/aws']);
        this.started = false;
      } else {
        localStorage.clear();
        this.notifier.alert(
          'Info',
          '',
          'Accounts not found. Please contact admin',
          'info',
          -1
        );
        this.router.navigate(['/auth/login']);
        this.notifier.loading(false);
        localStorage.clear();
        return;
      }
    }
    // await this.fetchAzureSubscriptions(null);

    if (this.accountIds.list.length == 0) {
      await this.loadRegions();
    }
  }

  // loadAGL() {
  //   this.accountGroups = JSON.parse(
  //     localStorage.getItem("selectedAccountGroupData")
  //   );
  //   this.clientNames = JSON.parse(
  //     localStorage.getItem("selectedClientNameData")
  //   );
  //   this.accountIds = JSON.parse(localStorage.getItem("accountIdData"));

  //   this.selectedRegion = localStorage.getItem("selectedRegion");
  //   //localStorage.setItem("selectedRegion", regions.name);
  //   localStorage.getItem("selectedRegionId");

  //   //for (var i = 0; i < this.agl[0].length; i++) {
  //   //  if()
  //   //}
  //   //this.accountGroups = {
  //   //  selected: this.agl[0].groupName,
  //   //  list: []
  //   //};

  //   //for (let i = 0; i < this.agl.length; i++) {
  //   //  this.accountGroups.list.push(this.agl[i]);
  //   //}
  // }

  async selectAccountGroup(accountGroup: any, refresh = true) {
    this.accountGroups.selected = accountGroup.groupName;

    this.setAccountGroup(this.accountGroups.selected);
    return;
    let value = accountGroup.accountGroup.accountGroupList.accountGroupDetails;
    console.log('accountGroup', value);

    localStorage.setItem('selectedAccountGroup', this.accountGroups.selected);
    localStorage.setItem(
      'selectedAccountGroupData',
      JSON.stringify(this.accountGroups)
    );

    this.clientNames = {
      selected: this.accountGroups.selected,
      list: value,
    };

    //for (let i = 0; i < this.clientNames.length; i++) {
    //  if (this.clientNames.list[i].cmpName == this.clientNames.selected) {
    this.selectclientName(this.clientNames.list[0], refresh);
    //      break;
    //    }

    //}
  }

  async selectclientName(clientName: any, refresh = true) {
    this.clientNames.selected = clientName.cmpName;

    // if (!clientName.accList || clientName.accList.length == 0) {
    //   this.notifier.alert('Error', 'Error', 'No Account Found!');

    //   this.accountIds = {
    //     selected: '',
    //     list: [],
    //   };
    //   return;
    // }

    // this.accountIds = {
    //   selected: clientName.accList[0].accountName,
    //   list: clientName.accList,
    // };

    localStorage.setItem('selectedClientName', this.clientNames.selected);
    localStorage.setItem(
      'selectedClientNameData',
      JSON.stringify(this.clientNames)
    );

    this.setClientList(clientName.emailId);
    return;
    // this.notifier.changeAccountIdNotification(
    //   this.accountIds.list[0].accountId
    // );

    // this.selectAccountId(this.accountIds.list[0]);
  }

  searchOnList(data: string, pattern: string) {
    if (!pattern) return true;

    if (data.toLowerCase().indexOf(pattern.toLowerCase()) != -1) return true;
    return false;
  }

  checkForDemoAccount() {
    if (this.clientNames['selected'] == 'DemoClient') {
      return true;
    }
    return false;
  }

  getSelectedAccount() {
    return this.checkForDemoAccount()
      ? '1234567890'
      : localStorage.getItem('accountId');
  }

  getSelectedRegion() {
    return localStorage.getItem('regionId');
  }

  resourceGroups: any = [];
  async fetchResourceGroups(account: any) {
    this.resourceGroups = [];

    let data: any = {
      action: 'list_resource_groups',
      user_id:
        this.urlPrefix == 'admin' &&
        (this.accountGroups['selected'] == 'DevelopmentTestGroup' ||
          this.accountGroups['selected'] == 'MinfyDevTeam')
          ? 'rakeshkumar.t@minfytech.com'
          : this.userId,
      subscription_id: account['accountId'],
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiUrl: string = `${environment.apiURL}/${this.urlPrefix}/azure/central`;

    let result: any = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == '1' || result.s == '1') {
      this.resourceGroups = result['resource_groups'];
      if (this.resourceGroups.length > 0) {
        this.resourceGroups.forEach((resource_group: any, index: number) => {
          if (index < 5) {
            resource_group['selected'] = true;
          }
          if (
            localStorage.getItem('selectedResourceGroup') == resource_group.name
          ) {
            this.changeResourceGroup(resource_group);
          }
        });
        if (!localStorage.getItem('selectedResourceGroup')) {
          this.changeResourceGroup(this.resourceGroups[0]);
        }
      }
      localStorage.setItem(
        'resourceGroups',
        JSON.stringify(this.resourceGroups)
      );
    }
  }

  changeResourceGroup(resource: any) {
    localStorage.setItem('selectedResourceGroup', resource['name']);
    this.selectedResourceGroup = resource.name;
    this.notifier.changeResourceGroup(resource.name);
  }

  selectedCloudPartner: any = 'aws';
  async selectAccountId(accountId: any) {
    if (accountId['accountId'] == '') {
      return false;
    }

    this.selectedCloudPartner = accountId['cp'].trim();
    localStorage.setItem('cp-selected', this.selectedCloudPartner);

    this.notifier.loading(false);
    this.notifier.loading(true);

    if (accountId['cp'] == 'azure') {
      await this.fetchResourceGroups(accountId);
    }

    this.accountIds.selected =
      accountId.accountName + ` (${this.getSelectedAccountId()})`;

    localStorage.setItem('selectedaccountIds', this.accountIds.selected);
    localStorage.setItem('accountId', accountId.accountId);
    localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));

    // if (
    //   !accountId["cft_status"] &&
    //   accountId["cft_status"] != undefined &&
    //   this.selectedCloudPartner != "azure"
    // ) {
    //   this.currentAccountCFTStatus = true;
    // }
    if (this.customerType == 'mp') {
      if (accountId['cft_status']) {
        this.started = true;
      } else {
        if (window.location.href.indexOf('accounts/aws') < 0) {
          this.router.navigate(['/dash/accounts/aws']);
        }
        this.started = false;
      }
    }
    await this.loadRegions();
    if (
      !localStorage.getItem('regionId') ||
      localStorage.getItem('regionId') == 'null'
    ) {
      let data = this.accountIds['list'].find((account: any) => {
        return account['accountId'] == localStorage.getItem('accountId');
      });

      if (data && data.regions && data.regions.length > 0) {
        let region: any = this.regions['enabled'].find((region: any) => {
          return region.id == data.regions[0];
        });
        if (region) {
          this.setRegion(region);
        } else {
          await this.setRegion(this.regions['enabled'][0], false);
        }
      } else if (this.regions['enabled'].length > 0) {
        await this.setRegion(this.regions['enabled'][0], false);
      }
    } else {
      this.setRegion(
        this.regions['enabled'].find((region: any) => {
          return region.id == localStorage.getItem('regionId');
        }),
        false
      );
    }
    this.notifier.loading(false);

    if (
      !localStorage.getItem('regionId') ||
      localStorage.getItem('regionId') == 'null'
    ) {
      return false;
    }
    this.checkForMandatory();
    if (accountId['cp'] == 'azure') {
      this.router.navigate(['/dash/home']);
    }
    if (this.selectedCloudPartner == 'aws') {
      setTimeout(() => {
        this.notifier.changeAccountIdNotification(accountId.accountId);
      }, 100);
    } else {
      this.notifier.loading(false);
    }

    return true;
  }

  getCurrentAccount() {
    let account = localStorage.getItem('accountId');
    if (account) {
      return this.accountIds.list.find((account_: any) => {
        return account_.accountId == account;
      });
    } else {
      return {};
    }
  }

  checkRegion(account: any, region_id: any) {
    return account.regions.indexOf(region_id) > -1;
  }

  async setRegion(regions: any, validate: boolean = true) {
    this.selectedRegion = regions.name;

    localStorage.setItem('selectedRegion', regions.name);
    localStorage.setItem('selectedRegionId', regions.id);
    localStorage.setItem('regionId', regions.id);

    if (validate) {
      this.notifier.changeRegionNotification(regions.id);
    }
  }

  async copyToClipboard(event: any, text: string) {
    event.preventDefault();
    event.stopPropagation();
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (e) {
        console.error('Error while copying code', e);
      }
    }
  }

  async logout() {
    this.notifier.loading(true);
    let data = {
      a: 'logout',
      email: this.userId || localStorage.getItem('eId'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${environment.apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    }

    localStorage.clear();
    setTimeout(() => {
      this.router.navigateByUrl('/auth/login');
      this.notifier.loading(false);
    }, 100);
  }

  hideLeftMenu() {
    $('.leftmenu').addClass('leftmenuhidden');
    $('.rightcontent').addClass('leftmenuhidden');
    // $('.dropdown-menu').removeClass('show');
    // $('#menuClosebtn').hide();
    // $('#menuShowbtn').show();
   const leftmenu:any = document.querySelector('.leftmenu');
   leftmenu.style.minWidth='0px';
  }

  showLeftMenu() {
    $('.leftmenu').removeClass('leftmenuhidden');
    $('.rightcontent').removeClass('leftmenuhidden');
    // $('#menuClosebtn').show();
    // $('#menuShowbtn').hide();
    const leftmenu:any = document.querySelector('.leftmenu');
    leftmenu.style.minWidth='200px';
  }

  // showSearch() {
  //   this.showSearchResult = true;
  // }

  // hideSearch() {
  //   this.showSearchResult = false;
  // }

  // onSearchChange() {
  //   let searchValue = this.searchValue;
  //   if (!searchValue) return;
  //   searchValue = searchValue.toLowerCase();
  //   this.searchResults = this.searchData.items.filter((a: any) => {
  //     if (a.keys.indexOf(searchValue) != -1) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  goTO(link: string) {
    this.showSearchResult = false;
    this.router.navigateByUrl(link);
  }

  expandDropdown(event: any, value: string) {
    event.stopPropagation();
    this.expanded[value] = !this.expanded[value];
  }

  //for notification
  notirefe(event: any) {
    // this.loadNotificationCount();
  }
  async loadNotificationCount() {
    let data = {};
    let header = {
      Authorization: 'Bearer ' + localStorage.getItem('t'),
    };

    let apiURL = `https://apis-eks.swayam.cloud/global_notification/count_notifications/?email_id=${this.userId}&user_type=internal`;
    try {
      await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
        if (data['total_notification_count'] <= 99) {
          this.noticount = data['total_notification_count'];
        } else if (data['total_notification_count'] >= 100) {
          this.noticount = '99+';
        } else {
          this.noticount = 0;
        }
      });
    } catch (error) {
      this.noticount = 0;
    }
  }

  viewNotification(event: any) {
    this.viewNotificationTrigger = !this.viewNotificationTrigger;
  }

  getStatus(){
    return this.apiServer.getServiceStatus();
  }

}
