import { Component, OnInit,ChangeDetectorRef  } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
//import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

import * as echarts from 'echarts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  videoUrl = '';

  servicebtn = '';
  servicStatus = '';
  serviceloader = false;
  showinfotab = false;
  pollingIntervalId:any='';
  username:any='';
  notshow=false;
  dstatus='';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    // this.loadGraph();
    // this.loadTrendGraph();
    this.showinfotab=false;
   this.username = localStorage.getItem('un'); 
   this.username = this.username.split(' ')[0] ;
   this.username = this.username.charAt(0).toUpperCase() + this.username.slice(1)
   
    this.loadVideo();
    this.checkstatus();
  }

  loadGraph() {
    var chartDom = document.getElementById('Graph');
    var myChart = echarts.init(chartDom);

    let option: any = {
      tooltip: { trigger: 'item' },
      legend: { top: '20%', left: '5%', orient: 'vertical' },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '80%'],
          avoidLabelOverlap: false,
          label: { show: false, position: 'center' },
          emphasis: { label: { show: true, fontSize: 20, fontWeight: 'bold' } },
          labelLine: { show: false },
          data: [
            { value: 1048, name: 'Search Engine' },
            { value: 735, name: 'Direct' },
            { value: 580, name: 'Email' },
            { value: 484, name: 'Union Ads' },
            { value: 300, name: 'Video Ads' },
          ],
          left: '40%',
        },
      ],
    };
    option && myChart.setOption(option);
  }
 
  loadTrendGraph() {
    var chartDom = document.getElementById('TGraph');
    var myChart = echarts.init(chartDom);

    let option: any = {
      tooltip: { trigger: 'axis' },
      legend: {
        data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
      },
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      toolbox: { feature: { saveAsImage: {} } },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      },
      yAxis: { type: 'value' },
      series: [
        {
          name: 'Email',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
          name: 'Union Ads',
          type: 'line',
          stack: 'Total',
          data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
          name: 'Video Ads',
          type: 'line',
          stack: 'Total',
          data: [150, 232, 201, 154, 190, 330, 410],
        },
        {
          name: 'Direct',
          type: 'line',
          stack: 'Total',
          data: [320, 332, 301, 334, 390, 330, 320],
        },
        {
          name: 'Search Engine',
          type: 'line',
          stack: 'Total',
          data: [820, 932, 901, 934, 1290, 1330, 1320],
        },
      ],
    };
    option && myChart.setOption(option);
  }

  async loadVideo() {
    let apiURL: string = `https://apis.swayam.ai/chatbot_bedrock/videogen?video_number=option_4`;

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let result: any = await this.apiServer.postDataPromis(apiURL, {}, {});

    this.videoUrl = result['video_url'];
  }

  showinfo() {
    this.showinfotab = true;
  }
  hideinfo(event: Event) {
    this.showinfotab = false;
    this.servicebtn = 'Disable All';
    this.servicStatus = 'Enable';
    this.apiServer.setserviceStatus(true);
    this.changeDetectorRef.detectChanges(); 
   // this.enableService();
  }

  async checkstatus() {
    console.log('enter');
    console.log(this.pollingIntervalId);
    if(this.pollingIntervalId){
      clearInterval(this.pollingIntervalId);
    }
    let url = `https://apis.swayam.ai/dashboard/services_status`;
    let res = await this.apiServer.postDataPromis(url, {}, {});
    if (res['status'] == null) {
      this.servicebtn = 'Enable All';
      this.servicStatus = 'Disable';
      this.notshow=false;
      this.showinfotab = true;
      this.apiServer.setserviceStatus(false);
      this.changeDetectorRef.detectChanges(); 
    } else if (res['status'] === 'created') {
      this.servicebtn = 'Disable All';
      this.servicStatus = 'Enable';
     
      this.showinfotab = false;
    } else if (res['status'] === 'creating') {
      this.servicStatus='Creating';
      this.servicebtn = 'Creating..';
      this.serviceloader=true;
      this.notshow=false;
      this.showinfotab = true;
      // this.pollingIntervalId = setInterval(async () => {
      //   let url = `https://apis.swayam.ai/dashboard/services_status`;
      //   let res = await this.apiServer.postDataPromis(url, {}, {});
      //   console.log(res['status']);
      //   if (res['status'] === 'created') {
      //     this.invokeKendra();
      //     this.servicebtn = 'Disable All';
      //     this.servicStatus = 'Enable';
      //     this.apiServer.setserviceStatus(true);
      //     this.changeDetectorRef.detectChanges(); 
      //     this.serviceloader=false;
      //     clearInterval(this.pollingIntervalId);
      //   } else {
      //     console.log('again');
      //   }
      // }, 20000);

    } else if (res['status'] === 'deleting') {
      this.servicebtn = 'Deleting..';
      this.serviceloader=true;
      this.pollingIntervalId = setInterval(async () => {
        let url = `https://apis.swayam.ai/dashboard/services_status`;
        let res = await this.apiServer.postDataPromis(url, {}, {});
        console.log(res['status']);
        if (res['status'] === null) {
          this.servicebtn = 'Enable All';
          this.servicStatus = 'Disable';
          this.serviceloader=false;
          this.showinfotab = true;
          this.dstatus='active';
          clearInterval(this.pollingIntervalId);
        } else {
          console.log('again');
        }
      }, 10000);

    }
  }

  // async enableService2() {
  //   if (this.btnstatus === 'enable') {
  //     this.btnstatus = 'creating..';
  //     console.log('enter');
  //     let url = `https://apis.swayam.ai/dashboard/create_services`;
  //     try {
  //       let res = await this.api.postDataPromis(url, {}, {});
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     this.pollingIntervalId = setInterval(async () => {
  //       let url = `https://apis.swayam.ai/dashboard/services_status`;
  //       let res = await this.api.postDataPromis(url, {}, {});
  //       console.log(res['status']);
  //       if (res['status'] === 'created') {
  //         this.btnstatus = 'disable';
  //         this.msg = 'enable';
  //         clearInterval(this.pollingIntervalId);
  //       } else {
  //         console.log('again');
  //       }
  //     }, 30000);
  //   } else if (this.btnstatus === 'disable') {
  //     let url = `https://apis.swayam.ai/dashboard/terminate_services`;
  //     let res = await this.api.postDataPromis(url, {}, {});
  //     this.pollingIntervalId = setInterval(async () => {
  //       let url = `https://apis.swayam.ai/dashboard/services_status`;
  //       let res = await this.api.postDataPromis(url, {}, {});
  //       console.log(res['status']);
  //       if (res['status'] === null) {
  //         this.btnstatus = 'enable';
  //         this.msg = 'disable';
  //         clearInterval(this.pollingIntervalId);
  //       } else {
  //         console.log('again');
  //       }
  //     }, 10000);
  //   }
  // }


  async enableService() {
    if (this.servicebtn === 'Enable All') {
      this.serviceloader = true;
      // this.servicStatus = 'Enable';
      // this.servicebtn = 'Disable All';
      this.servicebtn = 'Creating..';
      console.log('enter');
      let url = `https://apis.swayam.ai/dashboard/create_services`;
      try {
        let res = await this.apiServer.postDataPromis(url, {}, {});
      } catch (error) {
        console.log(error);
      }
      this.pollingIntervalId = setInterval(async () => {
        let url = `https://apis.swayam.ai/dashboard/services_status`;
        let res = await this.apiServer.postDataPromis(url, {}, {});
        console.log(res['status']);
        if (res['status'] === 'created') {
          this.invokeKendra();
          this.servicebtn = 'Disable All';
          this.servicStatus = 'Enable';
          this.serviceloader=false;
          clearInterval(this.pollingIntervalId);
        } else {
          console.log('again');
        }
      }, 30000);
    } else if (this.servicebtn === 'Disable All') {
      this.serviceloader=true;
     
      this.servicebtn = 'Deleting..';
      let url = `https://apis.swayam.ai/dashboard/terminate_services`;
      let res = await this.apiServer.postDataPromis(url, {}, {});

      this.pollingIntervalId = setInterval(async () => {
        let url = `https://apis.swayam.ai/dashboard/services_status`;
        let res = await this.apiServer.postDataPromis(url, {}, {});
        console.log(res['status']);
        if (res['status'] === null) {
          this.servicebtn = 'Enable All';
          this.servicStatus = 'Disable';
          this.notshow=true;
          this.showinfotab = true;
          this.dstatus='active';
          // this.apiServer.setserviceStatus(false);
          // this.changeDetectorRef.detectChanges(); 
          this.serviceloader=false;
          clearInterval(this.pollingIntervalId);
        } else {
          console.log('again');
        }
      }, 10000);
    }
  }

  checkstatusvalue(status:any){
    if(status==='Disable'){
      return 'Inactive';
    }else{
      return 'Active';
    }
  }

  async invokeKendra(){
    let url = `https://apis.swayam.ai/dashboard/kendra_ingestion`;
      let res = await this.apiServer.postDataPromis(url, {}, {});
  }
  ngOnDestroy() { clearInterval(this.pollingIntervalId); } 
}
