<!-- toggler -->
<!--<a class="navbar-brand" >

</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>-->
<!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
<button class="btn btn-primary" type="submit">Search</button> *ngIf="searchOnList(i.groupName,accountGroupSearchTerm)" -->
<!--<button class="btn float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
  <i class="bi bi-arrow-left-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
</button>-->
<!--<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
<!--<div class="leftmenu offcanvas offcanvas-start  show" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
  <div class="offcanvas-header">
    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
      <img style="width:50px" src="../assets/img/logo.png" />
    </h6>
  </div>-->

<div class="leftmenu" *ngIf="started" >
  <div class="offcanvas-body px-0 max-overflow">
    <!-- <button
      class="menuCloseButton ground-border"
      (click)="hideLeftMenu()"
      id="menuClosebtn"
    >
      <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
    </button>

    <button class="ground-border" (click)="showLeftMenu()" id="menuShowbtn">
      <i class="fa fa-arrow-circle-right"></i>
    </button> -->

    <ul
      class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
      id="menu"
    >

      <ng-container *ngIf="selectedCloudPartner == 'aws'" >
        <li class="nav-item" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
          <a
            routerLink="/dash/dashboard"
            class="nav-link text-truncate"
            title=""
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/Home.png"
              class="monitoringimg"
              alt=""
            />
            Home
          </a>
        </li>
        <li class="dropdown" *ngIf="checkIfAvaForUser('Chatbot Solutions')" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
          <a
            href="#"
            class="nav-link dropdown-toggle text-truncate"
            id="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/Chatbot.png"
              alt=""
              class="wid-40"
            />
            Chatbot Solutions
          </a>

          <ul
            class="dropdown-menu text-small shadow sub-menu"
            aria-labelledby="dropdown"
          >
            <li>
              <h3 class="sub-menu-title">Chatbot Solutions</h3>
              <hr />
            </li>

            <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Data on Website')">
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-web">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Data+%26+Website.png"
                  alt=""
                  class="wid-40"
                />
                <span>Data on Website</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Mp3')">
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-mp3">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/MP3.png"
                  class="wid-40"
                  alt=""
                /><span>Mp3</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Chatbot Solutions:PDF')">
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-pdf">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/PDF.png"
                  class="wid-40"
                  alt=""
                /><span>PDF</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Tabular')">
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-xlsx">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Tabular.png "
                  class="wid-40"
                  alt=""
                /><span>Tabular</span>
              </a>
            </li>
            <li >
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-ivr">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/MP3.png"
                  class="wid-40"
                  alt=""
                /><span>IVR</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Ticket')">
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-ticket">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Ticket.png"
                  class="wid-40"
                  alt=""
                /><span>Ticket</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Email')">
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-mail">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Email.png"
                  class="wid-40"
                  alt=""
                /><span>Email</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Finance')">
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-finance">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Finance.png"
                  class="wid-40"
                  alt=""
                /><span>Biomedical</span>
              </a>
            </li>
            <li >
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-chathistory">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Data+%26+Website.png"
                  class="wid-40"
                  alt=""
                /><span>Chat History</span>
              </a>
            </li>
            <li >
              <a class="dropdown-item" routerLink="/dash/pages/chatbot-chatanalytics">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Data+%26+Website.png"
                  class="wid-40"
                  alt=""
                /><span>Chat Analytics</span>
              </a>
            </li>
            <!-- <li *ngIf="checkIfAvaForUser('Operations:ServiceIntelligence')">
              <a class="dropdown-item" routerLink="/dash/operations/itsi">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Service+Intelligence.svg"
                  style="width: 40px;" alt=""><span>Service Intelligence</span>
              </a>
            </li> -->
          </ul>
        </li>

        <li class="dropdown" *ngIf="checkIfAvaForUser('Healthcare')" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
          <a
            href="#"
            class="nav-link dropdown-toggle text-truncate"
            id="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/HealthCare.png"
              alt=""
              class="wid-40"
            />
            Healthcare
          </a>

          <ul
            class="dropdown-menu text-small shadow sub-menu"
            aria-labelledby="dropdown"
          >
            <li>
              <h3 class="sub-menu-title">Healthcare</h3>
              <hr />
            </li>

            <li *ngIf="checkIfAvaForUser('Healthcare:Patient Discharge Summary')" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
              <a class="dropdown-item" routerLink="/dash/pages/pds">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Patient+Discharge+Summary.png"
                  class="shub"
                  alt=""
                /><span>Patient Discharge Summary</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Healthcare:Patient Discharge Summary')" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
              <a class="dropdown-item" routerLink="/dash/pages/pds-pd">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Patient+Heath+Score.png"
                  class="shub"
                  alt=""
                /><span>Patient Health Score</span>
              </a>
            </li>

          </ul>

          
        </li>

        <!-- <li class="dropdown" *ngIf="checkIfAvaForUser('Sentiment Analysis')">
          <a
            href="#"
            class="nav-link dropdown-toggle text-truncate"
            id="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/White/4x/Security%404x.png"
              alt=""
              class="wid-40"
            />
            Sentiment Analysis
          </a> -->

          <!-- <ul
            class="dropdown-menu text-small shadow sub-menu"
            aria-labelledby="dropdown"
          >
            <li>
              <h3 class="sub-menu-title">Sentiment Analysis</h3>
              <hr />
            </li>

            <li *ngIf="checkIfAvaForUser('Sentiment Analysis:Find out sentiments')">
              <a class="dropdown-item" routerLink="/dash/pages/sentiment">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Startup+Security+Baseline%404x.png"
                  class="shub"
                  alt=""
                /><span>Find out sentiments</span>
              </a>
            </li>

          </ul> -->

          <li *ngIf="checkIfAvaForUser('Sentiment Analysis:Find out sentiments')" class="nav-item" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
            <a
            routerLink="/dash/pages/sentiment"
              class="nav-link text-truncate"
              title=""
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/Sentiment+Analysis.png"
                class="monitoringimg"
                alt=""
              />
              Sentiment Analysis
            </a>
          </li>

          <li  class="nav-item" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
            <a
            routerLink="/dash/pages/hr"
              class="nav-link text-truncate"
              title=""
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/HR+Solutions.png"
                class="monitoringimg"
                alt=""
              />
              HR Solution
            </a>
          </li>

          
        <!-- </li> -->
  <li class="nav-item" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
        <a
          routerLink="/dash/pages/forecast"
          class="nav-link text-truncate"
          title=""
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/Forecast.png"
            class="monitoringimg"
            alt=""
          />
          Forecast
        </a>
      </li>
        <li class="dropdown" *ngIf="checkIfAvaForUser('Video')" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
          <a
            href="#"
            class="nav-link dropdown-toggle text-truncate"
            id="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/Video.png"
              alt=""
              class="wid-40"
            />
            Video
          </a>

          <ul
            class="dropdown-menu text-small shadow sub-menu"
            aria-labelledby="dropdown"
          >
            <li>
              <h3 class="sub-menu-title">Video</h3>
              <hr />
            </li>

            <li *ngIf="checkIfAvaForUser('Video:Text to Video')" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
              <a class="dropdown-item" routerLink="/dash/pages/ttv">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/blue-logo/Video.png"
                  class="shub"
                  alt=""
                /><span>Text to Video</span>
              </a>
            </li>

          </ul>

          
        </li>

      </ng-container>

      <ng-container *ngIf="selectedCloudPartner == 'azure'">
        <li class="nav-item" *ngIf="checkIfAvaForUser('Dashboard')">
          <a
            routerLink="/dash/home"
            class="nav-link text-truncate"
            title="Feedback"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
              class="monitoringimg"
              alt=""
            />
            Dashboard
          </a>
        </li>
      </ng-container>

      <li class="dropdown" *ngIf="checkIfAvaForUser('Settings')" (mouseenter)="showLeftMenu()" (mouseleave)="hideLeftMenu()">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/Settings.png"
            class="wid-40"
            alt=""
          />
          Settings
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Settings</h3>
            <hr />
          </li>
          <li
            *ngIf="
              (checkIfAvaForUser('Settings:users') && ut == 'admin') ||
              isUserAdmin
            "
          >
            <a class="dropdown-item" routerLink="/dash/settings/users">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User%404x.png"
                class="wid-40"
                alt=""
              />
              <span>Users</span>
            </a>
          </li>
          <!-- <li *ngIf="
                checkIfAvaForUser('Settings:operations/ec2groups') &&
                urlPrefix == 'client' &&
                ut == 'admin'
              ">
              <a class="dropdown-item" routerLink="/dash/settings/ec2group/view">
                <i class="fa fa-server"></i><span> EC2 Groups </span>
              </a>
            </li> -->
          <li
            *ngIf="
              checkIfAvaForUser('Settings:policies') &&
              urlPrefix == 'client' &&
              (ut == 'admin' || isUserAdmin)
            "
          >
            <a class="dropdown-item" routerLink="/dash/settings/policies">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Policies%404x.png"
                class="wid-40"
                alt=""
              />
              <span>Policies</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Settings:audit trail')">
            <a class="dropdown-item" routerLink="/dash/settings/audittrail">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Audit+Trail%404x.png"
                class="wid-40"
                alt=""
              />
              <span> Audit Trail </span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Settings:user management')">
            <a class="dropdown-item" routerLink="/dash/settings/profile">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User+Management%404x.png"
                class="wid-40"
                alt=""
              /><span>User Management</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- <li class="nav-item">
        <a
          routerLink="/dash/feedback"
          class="nav-link text-truncate"
          title="Feedback"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg"
            alt=""
          />
          Feature Request
        </a>
      </li> -->
    </ul>
  </div>
</div>

<div class="position-wid-99">
  <div class="row">
    <div class="col pad-right">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">
          <div class="nav-item wid-175">
            <span class="nav-link text-truncate padd-0">
              <!-- <img class="wid-115" src="../assets/img/swayam_logo_v3.0.png" /> -->
              <!-- <img
                class="wid-115"
                [attr.src]="whitelabelData['pd']['logoLight']"
              /> -->
               <img class="wid-124" src="https://s3.ap-south-1.amazonaws.com/assets.swayam.ai/logos/SwayamAI+Logo+%E2%80%94+White%E2%80%94Fullcolor.png" />
            </span>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">

              <!-- notification -->
              <!-- <li class="nav-item dropdown">
                <div class="notiBtn" (click)="viewNotification($event)">
                  <i class="fa-solid fa-bell"></i>
                  <span
                    *ngIf="noticount != 0"
                    class="badge badge-pill badge-danger bg-danger"
                    >{{ noticount }}</span
                  >
                 
                </div>
              </li> -->
              <!-- notification -->
              <li class="nav-item dropdown searchbox min-width-auto">
                <a
                  class="d-block text-decoration-none dropdown-toggle color-top"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <!-- <span class="color-family">{{ username }}</span> -->
                </a>
                <ul
                  class="dropdown-menu text-small"
                  aria-labelledby="dropdownUser1"
                  data-popper-placement="bottom-start"
                >
                  <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                    <li><a class="dropdown-item" href="#">Profile</a></li>-->
                  <li>
                    <span class="color-family text-bold">{{ username }}</span>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button class="dropdown-item" (click)="logout()">
                      Sign out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <div
              class="expiry-indicator float-right"
              *ngIf="
                expiresIn >= 0 &&
                customerType == 'mp' &&
                subType == 'free trail'
              "
            >
              <div class="progress-container">
                <svg class="progress-circle" width="40px" height="50px">
                  <circle
                    class="progress-circle-back"
                    cx="20"
                    cy="27"
                    r="12"
                  ></circle>
                  <circle
                    class="progress-circle-prog"
                    [ngStyle]="{
                      'stroke-dashoffset': getPercentage(
                        (this.expiresIn / totalPeriod) * 100
                      ),
                      stroke: this.expiresIn <= 3 ? 'red' : '#6872ff'
                    }"
                    cx="20"
                    cy="27"
                    r="12"
                  ></circle>
                </svg>
              </div>
              <div>
                <div>
                  <span
                    class="bold-text"
                    [ngStyle]="{
                      color: this.expiresIn <= 3 ? 'red' : '#6872ff'
                    }"
                    >{{ expiresIn }} / {{ totalPeriod }} Days</span
                  >
                </div>
                <div class="light-text">
                  {{ plans[subType] || "Free Trial" }}
                </div>
              </div>
            </div>

            <div class="dropdown text-end"></div>

            <div class="wid-75"></div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<div
  class="content min-vh-100 rightcontent"
  [ngStyle]="{
    marginLeft: !started ? '26px' : undefined
  }"
>
  <ng-container *ngIf="selectedCloudPartner == 'azure'">
    <div class="azure-resource-groups-container">
      <div class="azure-resource-groups">
        <div class="azure-resource-groups-absolute">
          <div
            *ngFor="let rg of resourceGroups"
            (click)="changeResourceGroup(rg)"
          >
            <div
              class="resource_group"
              *ngIf="rg.selected"
              [ngClass]="{ selected: selectedResourceGroup == rg.name }"
            >
              {{ rg.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="azure-resource-groups-actions">
        <!-- <ng-container *ngIf="checkForWidth()">
            <div><i class="fa fa-angle-left" aria-hidden="true" (click)="moveLeft()"></i></div>
            <div><i class="fa fa-angle-right" aria-hidden="true"></i></div>
          </ng-container> -->
        <div class="more-resource-groups-container">
          <div class="flex-end" (click)="showMore = !showMore">
            <span class="font-size-0-9rem">Configure Resource Groups</span>
            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
          </div>
          <div class="more-rgs" *ngIf="showMore">
            <div>
              <input
                type="text"
                placeholder="Search..."
                [(ngModel)]="filterText"
                class="form-control"
              />
              <div class="max-height-auto">
                <div *ngFor="let rg of filteredResourceGroups()">
                  <div class="rg">
                    <div
                      class="text-white-space"
                      (click)="changeResourceGroup(rg)"
                    >
                      {{ rg.name }}
                    </div>
                    <div>
                      <label
                        class="switch"
                        [ngClass]="{
                          disabled: selectedResourceGroup == rg.name
                        }"
                      >
                        <input
                          type="checkbox"
                          (click)="toggleResourceGroup(rg)"
                          [(ngModel)]="rg.selected"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</div>

<div class="cft-error-container" *ngIf="checkingCFT">
  <div class="popup-container">
    <div class="image-container">
      <img src="./assets/img/aws_swayam.svg" alt="" />
    </div>
    <div class="popup-description">
      Validating Swayam Integration with your account...
    </div>
    <div class="mt-2">
      <img
        src="../assets/img/loading_2.svg"
        alt="loading scans"
        class="wid-mar"
      />
    </div>
  </div>
</div>

<!-- <app-global-search
    *ngIf="showSearchResult"
    (hideModal)="showSearchResult = false"
  ></app-global-search> -->
<!--<cft-viewer></cft-viewer>-->
<!-- <dash-expired *ngIf="expiresIn && expiresIn == 0"></dash-expired> -->
<!-- <router-outlet></router-outlet> -->

<!-- Chatbot -->

