
<div class="row mt-2">
    <div class="col-12">
        <div class="h-hold">
        <h3 *ngIf="username !== 'Sales_'" class="text-bold">Welcome {{username}}</h3>
        <h3 *ngIf="username === 'Sales_'" class="text-bold">Welcome</h3>
        <span>Please initiate services before using Swayam.ai. <span (click)="showinfo()" class="cl" [ngClass]="{'disabled':servicebtn==='Creating..'}">Click here</span> to initiate.</span>
    </div>
    </div>
</div>

<div class="row">
    <div class="col-9">
        <div class="row">
            <div class="col-5">
                <div class="card box-shadow">
                    <div class="card-header">Categories</div>
                    <div class="card-body stage-tab">
                        <div class="pd-10" routerLink="/dash/pages/ttv">
                            <div class="catIcon"><i class="fa-solid fa-file-video"> </i></div>
                            <div class="cateName">Text to Video</div>
                        </div>
                        <div routerLink="/dash/pages/chatbot-mp3" class="pd-10">
                            <div class="catIcon"><i class="fa-solid fa-file-audio"> </i></div>
                            <div class="cateName">MP3</div>
                        </div>
                        <div routerLink="/dash/pages/chatbot-pdf" class="pd-10">
                            <div class="catIcon"><i class="fa-solid fa-file"> </i></div>
                            <div class="cateName">PDF</div>
                        </div>
                        <div routerLink="/dash/pages/chatbot-xlsx" class="pd-10">
                            <div class="catIcon"><i class="fa-solid fa-file-lines"> </i></div>
                            <div class="cateName">Tabular</div>
                        </div>
                        <div class="pd-10" routerLink="/dash/pages/pds">
                            <div class="catIcon"><i class="fa-solid fa-file-medical"> </i></div>
                            <div class="cateName">Patient Discharge Summary</div>
                        </div>
                        <div class="pd-10" routerLink="/dash/pages/chatbot-ticket" >
                            <div class="catIcon"><i class="fa-solid fa-ticket"> </i></div>
                            <div class="cateName">Ticket</div>
                        </div>
                        <div class="pd-10" routerLink="/dash/pages/chatbot-mail">
                            <div class="catIcon"><i class="fa-solid fa-envelope"> </i></div>
                            <div class="cateName">Email</div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            <div class="col-7">
                <div class="card box-shadow">
                    <div class="card-header sp"><span>Text to Video Solution (English)</span><span routerLink="/dash/pages/ttv"><i class="fa-solid fa-up-right-from-square "></i></span></div>
                    <div class="card-body video-container">
                        <video controls [src]="videoUrl"></video>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card box-shadow">
                    <div class="card-header sp"> <span>Patient Discharge Summary</span> <span routerLink="/dash/pages/pds-pd" ><i class="fa-solid fa-up-right-from-square "></i></span></div>
                    <div class="card-body videocontain">
                        <div class="pro-hold">
                            <div class="profile">
                                <i class="fa-solid fa-user"></i>
                            </div>
                            <span class="mt-10">John Smith</span>
                        </div>
                        <div class="gghold">
                            <div class="gr-hold">
                                <div class="progress-circle green over50 p90">
                                    <span>97</span>
                                    <div class="left-half-clipper">
                                        <div class="first50-bar"></div>
                                        <div class="value-bar"></div>
                                    </div>
                                    
                                </div>
                                <span>Kidney Health Score</span>
                            </div>
                        <div class="gr-hold">
                        <div class="progress-circle blue  p40">
                            <span>43</span>
                            <div class="left-half-clipper">
                                <div class="first50-bar"></div>
                                <div class="value-bar"></div>
                            </div>
                            
                        </div>
                        <span>Blood Pressure</span>
                    </div>
                    <div class="gr-hold">
                        <div class="progress-circle green p20">
                            <span>16</span>
                            <div class="left-half-clipper">
                                <div class="first50-bar"></div>
                                <div class="value-bar"></div>
                            </div>
                        </div>
                        <span>Haemoglobin</span>
                    </div>

                    <div class="gr-hold">
                        <div class="progress-circle green p10">
                            <span>0.74</span>
                            <div class="left-half-clipper">
                                <div class="first50-bar"></div>
                                <div class="value-bar"></div>
                            </div>
                        </div>
                        <span>Serum Creatinine</span>
                    </div>  
                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-3 d-flex flex-column">
        <div class="card box-shadow">
            <div class="card-header sh"><span>Services</span><span (click)="enableService()" class="btn btn-sm " [ngClass]="{'sbtn':servicebtn==='Enable All','sdbtn':servicebtn==='Disable All','cdbtn':servicebtn==='Creating..','dbtn':servicebtn==='Deleting..'}" >{{servicebtn}}</span></div>
            <div class="card-body">
                <table class="table table-hover  m-0 p-0">
                    <thead >
                      <!-- <tr>
                        
                        <td scope="col">Service</td>
                        <td scope="col">Status</td>
                      </tr> -->
                    </thead>
                    <tbody>
                        <tr>
                            
                           <td>AWS SageMaker</td>
                           <td *ngIf="!serviceloader"><span class="badge" [ngClass]="{'enable':servicStatus==='Enable','disable':servicStatus==='Disable'}">{{checkstatusvalue(servicStatus)}}</span></td>
                           <td *ngIf="serviceloader" class="text-center"><span class="loader" ></span></td>
                        </tr>
                        <tr>
                             
                            <td>AWS Kendra</td>
                            <td *ngIf="!serviceloader"><span class="badge" [ngClass]="{'enable':servicStatus==='Enable','disable':servicStatus==='Disable'}">{{checkstatusvalue(servicStatus)}}</span></td>
                            <td *ngIf="serviceloader" class="text-center"><span class="loader" ></span></td>
                         </tr>
                        <tr>
                             
                            <td>AWS Bedrock</td>
                            <td *ngIf="!serviceloader"><span class="badge" [ngClass]="{'enable':servicStatus==='Enable','disable':servicStatus==='Disable'}">{{checkstatusvalue(servicStatus)}}</span></td>
                            <td *ngIf="serviceloader" class="text-center"><span class="loader" ></span></td>
                         </tr>
                         <tr>
                             
                            <td>AWS Transcribe</td>
                            <td *ngIf="!serviceloader"><span class="badge" [ngClass]="{'enable':servicStatus==='Enable','disable':servicStatus==='Disable'}">{{checkstatusvalue(servicStatus)}}</span></td>
                            <td *ngIf="serviceloader" class="text-center"><span class="loader" ></span></td>
                         </tr>
                         <tr>
                             
                            <td>AWS Comprehend</td>
                            <td *ngIf="!serviceloader"><span class="badge" [ngClass]="{'enable':servicStatus==='Enable','disable':servicStatus==='Disable'}">{{checkstatusvalue(servicStatus)}}</span></td>
                            <td *ngIf="serviceloader" class="text-center"><span class="loader" ></span></td>
                         </tr>
                         <tr>
                             
                            <td>AWS Polly</td>
                            <td *ngIf="!serviceloader"><span class="badge" [ngClass]="{'enable':servicStatus==='Enable','disable':servicStatus==='Disable'}">{{checkstatusvalue(servicStatus)}}</span></td>
                            <td *ngIf="serviceloader" class="text-center"><span class="loader" ></span></td>
                         </tr>
                          
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="card box-shadow">
            <div class="card-header">Trend</div>
            <div class="card-body"></div>
        </div> -->
    </div>
</div>

<!-- *ngIf="showinfotab || servicStatus==='Disable'" -->

<app-info-tab *ngIf="showinfotab"  (hideModal)="hideinfo($event)" [servicStatus]="servicStatus" (cancelm)="showinfotab = false" [disableStatus]="dstatus"></app-info-tab>
