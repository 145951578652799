import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from "@ng-select/ng-select";
import { MenuSelectorComponent } from '../modules/globals/dash/modules/settings/components/menu-selector/menu-selector.component';

@NgModule({
  declarations: [
    MenuSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MenuSelectorComponent
  ]
})
export class SharedModule { }
