import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './modules/auth/components/auth/auth.component';
import { DataResolver } from 'src/app/services/whtielabel.resolver';
import { DashComponent } from './modules/globals/dash/dash.component';
import { SuperadminComponent } from './modules/globals/superadmin/superadmin.component';
import { PagesComponent } from './modules/globals/dash/components/pages/pages/pages.component';
import { AuthGuard } from './services/auth.guard';
import { DashboardComponent } from './modules/globals/dash/components/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/login',
  },
  {
    path: 'auth',
    component: AuthComponent,
    resolve: { data: DataResolver },
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dash',
    resolve: { data: DataResolver },
    component: DashComponent,
    loadChildren: () =>
      import('./modules/globals/dash/dash.module').then((m) => m.DashModule),
  },
  {
    path: 'superadmin',
    resolve: { data: DataResolver },
    component: SuperadminComponent,
    loadChildren: () =>
      import('./modules/globals/superadmin/superadmin.module').then((m) => m.SuperadminModule),
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    resolve: { data: DataResolver },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
