import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { tokenExpired } from "./api.service";

declare var $: any;
declare var window: any;

@Injectable({
  providedIn: "root",
})
export class NotifierService {
  constructor() {}

  tokenExpired = tokenExpired;

  loading(show: boolean) {
    if (show) {
      $("#loading").addClass("show");
    } else {
      $("#loading").removeClass("show");
    }
  }

  alert(
    title: string,
    title2: string,
    msg: string,
    type: string = "info",
    timeout: number = 9000,
    force: boolean = false
  ) {
    if (
      (this.tokenExpired["expired"] || this.tokenExpired["checking"]) &&
      !force
    ) {
      return;
    }
    let id = this.uuid4();
    if (msg == undefined) {
      if (type == "success") {
        msg = "Success";
      } else {
        msg = "Unknown Exception. Please contact admin";
      }
    }

    let imgURL = "/assets/img/infoIcon.png";

    if (type == "success") {
      imgURL = "/assets/img/green_tick.png";
    } else if (type == "warning") {
      imgURL = "/assets/img/warning_Icon.png";
    } else if (type == "error") {
      imgURL = "/assets/img/error_Icon.png";
    }

    let toast = `<div id="${id}" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <img src="${imgURL}" class="rounded me-2 height-25" alt="...">
    <strong class="me-auto">${title}</strong>
    <small>${title2}</small>
    <button type="button" class="btn-close dataclosebtn" data-bs-dismiss="toast" aria-label="Close"  data-tid='${id}'  ></button>
  </div>
  <div class="toast-body">
  ${msg}
  </div>
</div>`;
    //onclick="closeNotifier('${id}')"
    //fade

    $("#notifications").append(toast);

    if (timeout > 0) {
      // if (timeout) timeout = 9000;

      setTimeout(() => {
        window.closeNotifier(id);
      }, timeout);
    }
  }

  centerToast(
    title: string,
    title2: string,
    msg: string,
    type: string = "info",
    timeout: number = 9000
  ) {
    let id = this.uuid4();

    //let toast = `<div id=${id} class="toast  show" style="width: 450px; top: 10px; right: 10px; bottom: unset; left: unset; transform: unset;">
    //  <div class="toast-header">
    //    <strong class="me-auto">1</strong>
    //    <small>11 mins ago</small>
    //    <button type="button" class="btn-close" data-mdb-dismiss="toast" aria-label="Close" onclick="closeNotifier('${id}')" ></button>
    //  </div>
    //  <div class="toast-body">
    //    Stacking element
    //  </div>
    //</div>`;
    let imgURL = "/assets/img/infoIcon.png";

    if (type == "success") {
      imgURL = "/assets/img/green_tick.png";
    } else if (type == "warning") {
      imgURL = "/assets/img/warning_Icon.png";
    } else if (type == "error") {
      imgURL = "/assets/img/error_Icon.png";
    }

    /*  <div class="toast-header">
    <img src="${imgURL}" class="rounded me-2" alt="..." style="height: 25px;">
    <strong class="me-auto">${title}</strong>
    <small>${title2}</small>
    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onclick="closeNotifier('${id}')"  ></button>
  </div>
  */
    let toast = `<div id="${id}" class="toastcenter show" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-body">
  ${msg}
  </div>
</div>`;

    //fade

    $("body").append(toast);

    if (timeout > 0) {
      // if (timeout) timeout = 9000;

      setTimeout(() => {
        window.closeNotifier(id);
      }, timeout);
    }
  }

  messageBox(title: string, msg: string) {
    let id = this.uuid4();

    //let toast = `<div id=${id} class="toast  show" style="width: 450px; top: 10px; right: 10px; bottom: unset; left: unset; transform: unset;">
    //  <div class="toast-header">
    //    <strong class="me-auto">1</strong>
    //    <small>11 mins ago</small>
    //    <button type="button" class="btn-close" data-mdb-dismiss="toast" aria-label="Close" onclick="closeNotifier('${id}')" ></button>
    //  </div>
    //  <div class="toast-body">
    //    Stacking element
    //  </div>
    //</div>`;
    //let imgURL = "/assets/img/infoIcon.png";

    //if (type == "success") {
    //  imgURL = "/assets/img/green_tick.png";
    //} else if (type == "warning") {
    //  imgURL = "/assets/img/warning_Icon.png";
    //} else if (type == "error") {
    //  imgURL = "/assets/img/error_Icon.png";
    //}  <button type="button" class="btn btn-primary">Save changes</button>

    let toast = `

<div id="${id}" class="modal show" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${title}</h5>
        <button type="button" class="btn-close dataclosebtn" data-bs-dismiss="modal" aria-label="Close"  data-tid='${id}' ></button>
      </div>
      <div class="modal-body">
        <p>${msg}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary dataclosebtn" data-bs-dismiss="modal"  data-tid='${id}' >Close</button>
       
      </div>
    </div>
  </div>
</div>
`;

    //fade
    //onclick="closeNotifier('${id}')"
    //onclick="closeNotifier('${id}')"

    $("body").append(toast);
  }

  uuid4() {
    const ho = (n: any, p: any) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`
    const data = crypto.getRandomValues(new Uint8Array(16)); /// Fill the buffer with random data
    data[6] = (data[6] & 0xf) | 0x40; /// Patch the 6th byte to reflect a version 4 UUID
    data[8] = (data[8] & 0x3f) | 0x80; /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
    const view = new DataView(data.buffer); /// Create a view backed by a 16-byte buffer
    return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(
      view.getUint16(6),
      4
    )}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(
      view.getUint16(14),
      4
    )}`; /// Compile the canonical textual form from the array data
  }

  private messageSource = new BehaviorSubject(
    JSON.stringify({
      key: "resourceGroup",
      value: localStorage.getItem("selectedResourceGroup"),
    })
  );
  currentMessage = this.messageSource.asObservable();

  changeRegionNotification(regionId: string) {
    this.messageSource.next(`{"key" : "regionId", "value": "${regionId}", "source": "notifier" }`);
  }

  changeAccountIdNotification(accountId: string) {
    this.messageSource.next(`{"key" : "accountId", "value": "${accountId}", "source": "notifier" }`);
  }

  changeResourceGroup(resource_group: string) {
    this.messageSource.next(
      `{"key" : "resourceGroup", "value": "${resource_group}", "source": "notifier" }`
    );
  }

  sortTable(columData: any, dt: any) {
    console.log("columName", columData, dt);
    if (columData.sort && columData.sort.sort) {
      if (columData.sort.direction == "Asc") {
        console.log("columData.sort.direction Asc", columData.sort.direction);
        columData.sort.direction = "Des";

        dt = dt.sort((a: any, b: any) => {
          let aData = a[columData.id];
          let bData = b[columData.id];

          if (aData < bData) {
            return -1;
          }
          if (aData > bData) {
            return 1;
          }
          return 0;
        });
      } else {
        console.log("columData.sort.direction Des", columData.sort.direction);
        columData.sort.direction = "Asc";

        dt = dt.sort((a: any, b: any) => {
          let aData = a[columData.id];
          let bData = b[columData.id];

          if (aData < bData) {
            return 1;
          }
          if (aData > bData) {
            return -1;
          }
          return 0;
        });
      }
    }
  }

  filterTable(headeData: any, filterText: string, dt: any) {
    let res = true;

    for (let i = 0; i < headeData.length; i++) {
      if (headeData[i].filter) {
        res = false;
        if (
          !dt[headeData[i].id] ||
          dt[headeData[i].id].toLowerCase().indexOf(filterText.toLowerCase()) !=
            -1
        )
          res = true;
      }
    }

    return res;
  }

  filterTableColums(headeData: any, dt: any) {
    let res = true;

    for (let i = 0; i < headeData.length; i++) {
      if (headeData[i].filter && headeData[i].filterText) {
        res = false;
        if (
          !dt[headeData[i].id] ||
          dt[headeData[i].id]
            .toLowerCase()
            .indexOf(headeData[i].filterText.toLowerCase()) != -1
        ) {
          res = true;
        } else {
          break;
        }
      }
    }

    return res;
  }

  enableScroll() {
    document.body.classList.remove("no-scroll");
  }
  disableScroll() {
    document.body.classList.add("no-scroll");
  }
}
