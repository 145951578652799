import { Component, EventEmitter, Input, Output } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
@Component({
  selector: 'app-info-tab',
  templateUrl: './info-tab.component.html',
  styleUrls: ['./info-tab.component.css']
})
export class InfoTabComponent {



  @Output() hideModal = new EventEmitter();
  @Output() cancelm = new EventEmitter();
  // @Input() status:any;
  @Input() servicStatus:any;
  @Input() disableStatus:any='';
  
  servicebtn = '';
  // servicStatus = '';
  serviceloader = false;
  showinfotab = false;
  pollingIntervalId: any = '';
  progressPercent: any = 0;
  progressInterval: any = '';

  isbedrock=false;
  ispolly=false;
  iskendra=false;
  iscomp=false;
  issagemaker=false;
  istrans=false;
  constructor(
    private apiServer: APIService
  ) {}
  
  no(){ this.hideModal.emit({ status: 0 }); } 
 cancel(){ this.cancelm.emit({ status: 0 }); } 

ngOnInit(){
  console.log('----',this.servicStatus);
  if(this.servicStatus!== 'Disable' && this.servicStatus!== 'Creating'){
    this.isbedrock=true;
    this.ispolly=true;
    this.iskendra=true;
    this.iscomp=true;
    this.issagemaker=true;
    this.istrans=true;
  }else if(this.servicStatus === 'Creating'){
    this.progress(55, 98, 1000);
    this.enableeachService('creating');
    this.serviceloader = true;
    this.pollingIntervalId = setInterval(async () => {
      let url = `https://apis.swayam.ai/dashboard/services_status`;
      let res = await this.apiServer.postDataPromis(url, {}, {});
      console.log(res['status']);
      if (res['status'] === 'created') {
        this.progress(99, 100, 2000);
        this.enableeachService('stop');
      setTimeout(() => {
        this.servicStatus = 'Active';
        this.invokeKendra();
        this.servicebtn = 'Disable All';

        this.serviceloader = false;

        clearInterval(this.pollingIntervalId);
      }, 1000);
      } else {
        console.log('again');
      }
    }, 30000);
  }
}

 async enableService() {
  this.servicStatus = 'Creating';
  this.progress(0, 99, 1000);
  this.enableeachService('start');
  this.serviceloader = true;
  // this.servicStatus = 'Enable';
  // this.servicebtn = 'Disable All';
  this.servicebtn = 'Creating..';
  console.log('enter');
  let url = `https://apis.swayam.ai/dashboard/create_services`;
  try {
    let res = await this.apiServer.postDataPromis(url, {}, {});
  } catch (error) {
    console.log(error);
  }

  this.pollingIntervalId = setInterval(async () => {
    let url = `https://apis.swayam.ai/dashboard/services_status`;
    let res = await this.apiServer.postDataPromis(url, {}, {});
    console.log(res['status']);
    if (res['status'] === 'created') {
      this.progress(99, 100, 2000);
      this.enableeachService('stop');
      setTimeout(() => {
        this.servicStatus = 'Active';
        this.invokeKendra();
        this.servicebtn = 'Disable All';

        this.serviceloader = false;

        clearInterval(this.pollingIntervalId);
      }, 1000);
    } else {
    }
  }, 20000);
}

// progress(start: any, end: any, delay: any) {
//   if (this.progressInterval) {
//     clearInterval(this.progressInterval);
//   }
//   let currentProgress = start;
//   this.progressInterval = setInterval(() => {
//     if (currentProgress <= end) {
//       this.progressPercent = currentProgress;
//       currentProgress++;
//     } else {
//       clearInterval(this.progressInterval);
//     }
//   }, delay);
// }

progress(start: any, end: any, delay: any) {
  if (this.progressInterval) {
    clearInterval(this.progressInterval);
  }
  let currentProgress = start;
  this.progressInterval = setInterval(() => {
    if (currentProgress <= end) {
      this.progressPercent = currentProgress;
      currentProgress++;
    } else {
      clearInterval(this.progressInterval);
    }
  }, delay);
}
async invokeKendra() {
  let url = `https://apis.swayam.ai/dashboard/kendra_ingestion`;
  let res = await this.apiServer.postDataPromis(url, {}, {});
}

progressDone() {
  this.servicStatus = 'Enable';
  this.progressPercent = 0;
  this.hideModal.emit({ status: 0 });
}


enableeachService(action:any){
if(action==='start'){
  setTimeout(() => {
    this.isbedrock=true;
    setTimeout(() => {
      this.istrans=true;
      setTimeout(() => {
        this.iscomp=true;
        setTimeout(() => {
          this.ispolly=true;
          setTimeout(() => {
            this.issagemaker=true;
          }, 17000);
        }, 12000);
      }, 10000);
    }, 9000);
  }, 7000);
}else if(action === 'stop'){
  this.iskendra=true;
  this.isbedrock=true;
  this.istrans=true;
  this.iscomp=true;
  this.ispolly=true;
  this.issagemaker=true;
}else if(action === 'creating'){
  this.isbedrock=true;
  this.istrans=true;
  this.iscomp=true;
  this.ispolly=true;
  this.issagemaker=true;
}
}



ngOnDestroy(): void {
  clearInterval(this.pollingIntervalId);
}



}
